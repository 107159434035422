import {
  AspectRatio,
  Avatar,
  Box,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'

import { HomepageSection } from './constants'
import { HomeContainer } from './HomeContainer'

type TestimonialProps = {
  quote: JSX.Element
  authorDetail: JSX.Element
  authorImageUrl: string
}
const Testimonial = ({
  quote,
  authorDetail,
  authorImageUrl,
}: TestimonialProps) => {
  return (
    <Box
      px={8}
      py={6}
      borderRadius="xl"
      bg="white"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="linen.500"
      boxShadow="lg"
      lineHeight={{ base: 1.5, lg: 1.75 }}
    >
      <Stack spacing={3}>
        <Text fontSize={{ base: 'md', lg: 'lg' }}>{quote}</Text>
        <Stack direction="row" align="center" spacing={2}>
          <Avatar src={authorImageUrl} boxSize="10" boxShadow="md" />
          <Text fontSize={{ base: 'sm', lg: 'md' }}>{authorDetail}</Text>
        </Stack>
      </Stack>
    </Box>
  )
}
export const HomeSection = ({
  eyebrow,
  heading,
  description,
  testimonial,
  videoUrls,
}: HomepageSection) => {
  return (
    <HomeContainer
      minHeight={{ base: '100vh', lg: '80vh' }}
      justifyContent="center"
      alignContent="center"
      data-home-section
    >
      <Stack
        direction={{ base: 'column-reverse', lg: 'row' }}
        color="gray.900"
        spacing={{ base: 8, lg: 20, xl: 32 }}
        alignItems="center"
      >
        <Stack spacing={6} w={{ base: '100%', lg: '50%', xl: '40%' }}>
          <Heading
            size={{ base: 'sm', lg: 'md' }}
            letterSpacing="normal"
            fontFamily="var(--body-font), sans-serif"
            color="gray.500"
            fontWeight="400"
          >
            {eyebrow}
          </Heading>
          <Heading
            size={{ base: 'xl', lg: '2xl' }}
            lineHeight="1 !important"
            fontWeight="700"
          >
            {heading}
          </Heading>
          <Box
            fontSize={{ base: 'md', lg: 'lg' }}
            lineHeight={{ base: 1.5, lg: 1.75 }}
          >
            {description}
          </Box>
          <Testimonial
            quote={testimonial.quote}
            authorDetail={testimonial.authorDetail}
            authorImageUrl={testimonial.authorImageUrl}
          />
        </Stack>
        <Box w={{ base: '100%', lg: '50%', '2xl': '60%' }}>
          <AspectRatio ratio={16 / 9}>
            <Box w="100%" borderRadius="xl" boxShadow="2xl">
              <video autoPlay loop muted>
                <source src={videoUrls.mp4} />
                <source src={videoUrls.webm} />
              </video>
            </Box>
          </AspectRatio>
        </Box>
      </Stack>
    </HomeContainer>
  )
}
