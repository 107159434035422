import {
  AspectRatio,
  Box,
  Button,
  Center,
  Heading,
  Image,
  Link,
  Stack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import doorImg from 'publicImages/door.png'
import footerTopImg from 'publicImages/footer-top.png'
import gammaAnimationImg from 'publicImages/gamma-animation.gif'
import leftSideImg from 'publicImages/Left-Side.png'
import rightSideImg from 'publicImages/Right-Side.png'

import { ORDERED_HOMEPAGE_SECTIONS } from './constants'
import { HomeContainer } from './HomeContainer'
import { HomeSection } from './HomeSection'

export const Home = () => {
  return (
    <Stack w="100%" bg="linen.50" spacing={{ base: 24, lg: 0 }}>
      <Stack
        spacing={24}
        w="100%"
        bg="linear-gradient(#faf2e9, rgba(250, 242, 233, 0))"
        pt={40}
      >
        <Box w="100%" position="relative">
          <Box
            w="100%"
            position="absolute"
            zIndex="1"
            opacity={{ base: 0, sm: '.15', '2xl': '.75' }}
            justifyContent="space-between"
            display="flex"
            top="5vh"
            bottom="0%"
            pointerEvents="none"
            left="0%"
            right="0%"
          >
            <Box
              w="50%"
              h="100%"
              bgImage={`url(${leftSideImg.src})`}
              bgSize="contain"
              bgRepeat="no-repeat"
              bgPosition="left"
              display={['none', 'none', 'block']}
              pointerEvents="none"
            />
            <Box
              w="50%"
              h="100%"
              bgImage={`url(${rightSideImg.src})`}
              bgSize="contain"
              bgRepeat="no-repeat"
              bgPosition="right"
              display={['none', 'none', 'block']}
              pointerEvents="none"
            />
          </Box>
          <HomeContainer>
            <Stack
              spacing={{ base: 4, lg: 8 }}
              minH={{ base: undefined, lg: '55vh' }}
              justifyContent="center"
              alignItems="center"
              w="100%"
            >
              <Heading
                size={{ base: '2xl', lg: '6xl' }}
                maxW={{ base: '100%', md: '80%' }}
                variant="fancy"
                textAlign="center"
                lineHeight="1.07 !important"
              >
                <Trans>A new medium for presenting ideas.</Trans>
              </Heading>
              <Heading
                color="blackAlpha.800"
                mt={{ base: -1, lg: -3 }}
                size={{ base: 'xl', lg: '5xl' }}
              >
                <Trans>Powered by AI.</Trans>
              </Heading>
              <Heading
                size={{ base: 'sm', lg: 'md' }}
                maxW={`min(560px, 100%)`}
                textAlign="center"
                variant="sansSerif"
                color="blackAlpha.700"
                lineHeight={'1.65 !important'}
              >
                <Trans>Beautiful presentations, documents, and websites.</Trans>{' '}
                <Trans>No design or coding skills required.</Trans>
              </Heading>
              <Center>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    size="xl"
                    variant="fancy"
                    as={Link}
                    href="/signup"
                    w="fit-content"
                  >
                    <Trans>Sign up for free</Trans>
                  </Button>
                </Stack>
              </Center>
            </Stack>
          </HomeContainer>
        </Box>
        <HomeContainer>
          <AspectRatio ratio={16 / 9}>
            <Box w="100%" borderRadius="xl" boxShadow="2xl">
              <iframe
                src="https://www.youtube.com/embed/r9VOYJM6nZc?rel=0"
                width="100%"
                height="100%"
                allow="encrypted-media"
                title="Gamma video"
                sandbox="allow-forms allow-scripts allow-same-origin allow-modals allow-pointer-lock allow-presentation"
                allowFullScreen={true}
              />
            </Box>
          </AspectRatio>
        </HomeContainer>
        {ORDERED_HOMEPAGE_SECTIONS.map((section, ind) => (
          <HomeSection key={ind} {...section} />
        ))}
      </Stack>
      <Stack spacing={0}>
        <Center display={{ base: 'none', lg: 'flex' }} pb={4}>
          <Image
            src={gammaAnimationImg.src}
            transform="translate3d(0px, -19.4672px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)"
            willChange="transform"
            borderRadius="full"
            border="4px solid #ffeef3"
            overflow="hidden"
            w="200px"
          />
        </Center>
        <Box
          display={{ base: 'none', lg: 'block' }}
          w="100%"
          h="400px"
          bgImage={`url(${footerTopImg.src})`}
          bgPosition="bottom"
          bgSize="contain"
          bgRepeat="no-repeat"
        />
        <Box bg="#1d0042" w="100%" pt={{ base: 16, md: 32 }}>
          <HomeContainer>
            <Stack
              w="100%"
              alignItems="center"
              justifyContent="center"
              spacing={8}
            >
              <Heading
                size="3xl"
                color="whiteAlpha.500"
                variant="fancyDark"
                textAlign="center"
              >
                <Trans>Open the door to a new universe</Trans>
              </Heading>
              <Heading
                size="md"
                textAlign="center"
                variant="sansSerif"
                lineHeight={1.65}
                color="whiteAlpha.500"
              >
                <Trans>
                  More visual than a doc. More collaborative than a slide deck.
                  More interactive than a video.
                </Trans>
              </Heading>

              <Button variant="fancy" size="xl" width="fit-content">
                <Trans>Try Gamma for free</Trans>
              </Button>
              <Image
                w="800px"
                maxW="80vw"
                src={doorImg.src}
                alt="Open the door to a new universe"
              />
            </Stack>
          </HomeContainer>
        </Box>
      </Stack>
    </Stack>
  )
}
